var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{attrs:{"title":""}},[_c('div',[_c('vue-element-loading',{attrs:{"active":_vm.loading,"color":"#00cca2","spinner":"spinner"}}),_c('ve-histogram',{attrs:{"data":_vm.leads_data,"height":_vm.heightStr,"legend":{bottom:10,show:true},"judge-width":"","grid":{right: '1%'},"mark-line":_vm.markLine,"title":{
                 text:(_vm.title + "（" + (_vm.leads_data.title?_vm.leads_data.title:'0') + "）"),
                 subtext: _vm.start_date==_vm.end_date?("" + _vm.start_date):(_vm.start_date + " ~ " + _vm.end_date),
                 textStyle: {
                     fontSize: 15,
                 },
                 subtextStyle: {
                     fontSize: 12.5
                 }
             },"toolbox":_vm.toolbox,"settings":{
                 label: { show: true, position: 'top'},
                 yAxisType: ['KMB', 'KMB'],
                 axisSite: { right: ['收入'] }, 
                 showLine: ['收入']
             },"extend":_vm.histogramChartExtend}}),(_vm.first>0 && _vm.leads_data.rows.length == 0)?_c('LEmpty'):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }