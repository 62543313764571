<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>市场数据分析</a-breadcrumb-item>
            <a-breadcrumb-item>线索总量分析</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="clearfix table-tools">
                <div class="buttons">
                </div>
                <div class="search">
                    <a-form layout='inline'>
                    <a-form-item>
                        <a-radio-group :value="paramsData.filter_type" @change="handleButChange">
                        <a-radio-button value="day">
                            日
                        </a-radio-button>
                        <a-radio-button value="week">
                            周
                        </a-radio-button>
                        <a-radio-button value="month">
                            月
                        </a-radio-button>
                        <a-radio-button value="year">
                            年
                        </a-radio-button>
                        </a-radio-group>
                    </a-form-item>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>时间筛选</span>
                        </template>
                        <a-range-picker
                            :ranges="rangesData"
                            :value='rangesValue'
                            :disabled-date="disabledDate" :allowClear="false"
                            :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"
                            style="width: 240px"
                            @change="(date, dateString)=>onChange(date, dateString,1)" />
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-button @click="toScreen" :loading="loading" type="primary">筛选</a-button>
                    </a-form-item>
                    <a-form-item>
                        <a-popover placement="bottom" trigger="click" v-model="visible">
                        <template slot="content">
                            <div class="more-search">
                            <a-form layout='inline'>
                                <a-form-item class="block-line" label="添加人">
                                    <a-select mode="multiple" :maxTagCount="1" :maxTagTextLength='5' v-model='paramsData.created_by' allowClear showSearch @search="handleSearch" placeholder="请选择添加人" :filterOption="filterOption">
                                    <a-select-option v-for="(d, index) of staffList" :key="index" :value="d['employee_id']">{{ d['filter_name'] }}</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-form>
                            <div class="more-search__buttons">
                                <a-button type="dashed" @click="closeSearch">取消</a-button>
                                <a-button @click="reset">重置</a-button>
                                <a-button type="primary" @click="toScreen">搜索</a-button>
                            </div>
                            </div>
                        </template>
                        <a-button>更多<a-icon type="down" /></a-button>
                        </a-popover>
                    </a-form-item>
                    </a-form>
                </div>
            </div>   
            <div class="dashboard bk-group" style="padding-top:15px">
                <a-row class="mb16" :gutter="16">
                    <a-col :lg="24" :xl="24">
                        <culeCount title="活动数据统计" :loading='loading'  :leads_data ='meetingData' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>
                    <a-col :lg="24" :xl="12">
                        <VErings :loading='loadingB' :item ='bookedData' title="活动报名数据统计" subtext='' />
                    </a-col>
                    <a-col :lg="24" :xl="12">
                        <VErings :loading='loadingC' :item ='checkedData' title="活动签到数据统计" subtext='' />
                    </a-col>
                </a-row>
            </div>
        </div>
    </div>
</template>

<script>
import ranges from "@/common/mixins/ranges"
import moment from 'moment'
import culeCount from './cule_count'
    export default {
        name:'meeting',
        data() {
            return {
                loading:false,
                loadingB:false,
                loadingC:false,
                visible:false,
                meetingData:{},
                bookedData:{},
                checkedData:{},
                studios:[],
                staffList:[],
                parentChannels:[],
                paramsData:{
                    start_date:moment().startOf('month').format('YYYY-MM-DD'),
                    end_date:moment().format('YYYY-MM-DD'),
                    filter_type:'day'
                },
                channelsId:[],
            }
        },
        components:{
            culeCount
        },
        mixins: [ranges],
        created() {
            this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
            this.toScreen()
            this.getStaff()
            this.getStudio()
            this.getSourceChannel()
        },
        methods: {
            moment,
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            getList(obj){
                this.getMeeting(obj)
                this.getMeetingBooked(obj)
                this.getMeetingChecked(obj)
            },
            async getMeeting(obj){
                this.loading = true
                let res = await this.$store.dispatch('analysisMeetingAction', obj)
                this.meetingData = res.data
                this.loading = false
            },
            async getMeetingBooked(obj){
                this.loadingB = true
                let res = await this.$store.dispatch('analysisMeetingBookedAction', obj)
                this.bookedData = res.data
                this.loadingB = false
            },
            async getMeetingChecked(obj){
                this.loadingC = true
                let res = await this.$store.dispatch('analysisMeetingCheckedAction', obj)
                this.checkedData = res.data
                this.loadingC = false
            },
            async getSourceChannel() {
                let res = await this.$store.dispatch('searchSourceChannelCascaderAction', {})
                this.parentChannels = res.data
            },
            async getStaff(val){
                let obj = {q:val}
                let res = await this.$store.dispatch('searchAccountEmployeeAction', {params:obj})
                this.staffList = res.data
            },
            async getStudio() {
                let res = await this.$store.dispatch('searchBelongStudioAction', {})
                this.studios = res.data
            },
            handleButChange(e){
                this.paramsData.filter_type = e.target.value
                this.toScreen()
            },
            closeSearch() {
                this.visible = false
            },
            reset(){
                this.paramsData.channel_id = undefined
                this.channelsId = []
                this.paramsData.studio_id = undefined
                this.paramsData.created_by = undefined
            },
            toScreen(){
                let obj = {
                    search:this.paramsData
                }
                this.getList(obj)
            },
            handleSearch(val){
                this.getStaff(val)
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>